const Footer = () => {
  return (
    <div className='footer'>
      <p>Copyright &copy; 2020 Auckland Hardware. All rights reserved.</p>
      <p>Made by Si Design</p>
    </div>
  )
}

export default Footer
