import Footer from './Footer'

const Contact = () => {
  return (
    <div className='contact'>
      <h1>Contact Us</h1>
      <div className='contactLinks'>
        <h3>
          <a href='mailto:dave@aucklandhardware.co.nz'>dave@aucklandhardware.co.nz</a>
        </h3>
        <h3>
          <a href='tel:+224444991'>022 444 4991</a>
        </h3>
      </div>
      <Footer />
    </div>
  )
}

export default Contact