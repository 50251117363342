import { Link} from 'react-scroll'

import logo from '../../assets/images/logo.svg'

const NavLogo = ({scrolling}) => {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  // const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  if (vw > 450)   
    return (
      <div className='navLogo'>
        <Link
          activeClass="active"
          to="hero"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          <h1 style={{display: 'flex', alignItems: 'center'}}>
            <span>Auckland <span className={scrolling ? 'accent' : ''}>Hardware</span></span>
            {scrolling ? <img src={logo} alt='Lock logo' className='svgLogo'/> : null}
          </h1>
        </Link>
      </div>
    )
  else return (
    <div className='navLogo'>
      <Link
        activeClass="active"
        to="hero"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <h1 style={{display: 'flex', alignItems: 'center'}}>
          A <span className={scrolling ? 'accent' : ''}>H</span>
          {scrolling ? <img src={logo} alt='Lock logo' className='svgLogo'/> : null}
        </h1>
      </Link>
    </div>
  )
}

export default NavLogo