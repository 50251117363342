
const Services = () => {
  return (
    <div className='services content'>
      <div className='content-title'>
        <h2>Our services</h2>
        <h3 className='accent'>What we can do for you.</h3>
      </div>
      <div className='content-copy'>
        <p>
          Auckland Hardware has been in the industry for nearly 20 years and knows how quality installation is achieved.
          We operate Auckland wide, working with all well known products such as Chant, Mardeco, Lockwood and more.
        </p>

        <p>
          As members of Site Safe New Zealand, we are proud of the high safety standards we have set over the years.
        </p>

        <p>
          Auckland Hardware has worked with almost every major construction company in New Zealand, from large commercial construction companies to small independent developers.
          Companies such as: Fletchers, Leigh Construction, Macrennie Commercial Construction, and many more.
        </p>

        <p>
          We partner with and can provide qualified electricians to run data and security wiring for all electrical products such as:
        </p>

        <ul>
          <li>Magnetic locks</li>
          <li>Swipe card access control</li>
          <li>Electrical closers</li>
          <li>Fire Egress Systems</li>
        </ul>

        <p>
          We use the best tools, jigs and template machinery supplied by Chant Productions to help produce the high end finish we require on all of our jobs.
        </p>

        <h4>Get in touch with us below for your hardware installation needs.</h4>
      </div>
    </div>
  )
}

export default Services
