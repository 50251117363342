const About = () => {
  return (
    <div className='about content'>
      <div className='content-title'>
        <h2>Auckland Hardware</h2>
        <h3 className='accent'>We know hardware.</h3>
      </div>
      <div className='content-copy'>
        <p>
          Auckland Hardware was established in 2005 and specialises in the commercial sector.
          We provide installation of all types of security hardware from locks, door closers, architectural hardware to a range of electrical and digital locks Auckland wide.
          We have installed on all types of premium sites, such as:
        </p>
        <ul>
          <li>Auckland Museum</li>
          <li>Commercial Bay</li>
          <li>US Embassy</li>
          <li>AUT and Auckland University</li>
          <li>Forsyth Barr Stadium</li>
          <li>Spark Arena</li>
          <li>Sky City VIP Black Room</li>
          <li>Mt Smart Stadium</li>
        </ul>
        
        <h4>View some of our projects and clients testimonials below.</h4>
        <div className='testimonials'>
          <p>
            "Dave at Auckland Hardware is our first choice when it comes to Hardware installation at Auckland Museum."
              <span><br />- Construction Manager for Aspec Construction</span>
          </p>
          <p>
            “Auckland Hardware has completed a number of contracts for us over the years and are the best in the business."
              <span><br />- Construction Manager for Woodview Construction</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
