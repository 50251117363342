import { useState, useContext, useEffect } from 'react'

import { NavToggleContext } from './NavToggleContext'

import '../../styles/hamburger.css'

const Hamburger = ({ scrolling }) => {
  const [navToggle, setNavToggle] = useContext(NavToggleContext)
  const [toggleClass, setToggleClass] = useState('hamburger hamburger--spring')

  useEffect(() => {
    if (!navToggle) setToggleClass('hamburger hamburger--spring')
  }, [navToggle])

  const clickHandler = () => {
    navToggle ? setNavToggle(false) : setNavToggle(true)
    toggleClass === 'hamburger hamburger--spring' ? setToggleClass('hamburger hamburger--spring is-active') : setToggleClass('hamburger hamburger--spring')
  }

  return (
    <button className={toggleClass} onClick={clickHandler}>
      <span className='hamburger-box'>
        <span className={scrolling ? 'hamburger-inner-accent' : 'hamburger-inner'}></span>
      </span>
    </button>
  )
}

export default Hamburger