import { useState, useEffect } from 'react'


import Hamburger from './Hamburger'
import FlyoutNav from './FlyoutNav'
import NavLinks from './NavLinks'
import NavLogo from './NavLogo'
import useViewport from '../../useViewport'

import '../../styles/nav.css'

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false)
  const { width } = useViewport()
  const breakpoint = 1200

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 0) setScrolling(true)
      if (window.pageYOffset === 0) setScrolling(false)
    }
  }, [])

  const className = scrolling ? 'navbar scroll' : 'navbar'

  return (
    <>
      <div className={className}>
        <NavLogo scrolling={scrolling} />
        {
          width > breakpoint ?
            <NavLinks scrolling={scrolling} /> :
            <Hamburger scrolling={scrolling} />
        }
      </div>
      <FlyoutNav />
    </>
  )
}

export default Navbar

