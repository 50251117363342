import { Link } from 'react-scroll'

const NavLinks = ({ scrolling }) => {
  return (
    <div className='navLinks'>
      <Link
        activeClass="active"
        to="about"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      ><h4>About</h4></Link>

      <Link
        activeClass="active"
        to="services"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      ><h4>Services</h4></Link>

      <Link
        activeClass="active"
        to="contact"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      ><h4>Contact</h4></Link>

      <h4>|</h4>
      <h3 className={scrolling ? 'accent' : ''}>
        <a href='tel:+224444991'>022 444 4991</a>
      </h3>
    </div>
  )
}

export default NavLinks