import museum from '../assets/images/work/museum.jpeg'
import stadium from '../assets/images/work/stadium.jpg'
import skycity from '../assets/images/work/skycity.jpg'

const ExampleWork = () => {
  return (
    <div className='exampleWorkGrid '>
      <div className='card'>
        <div className='cardImage'>
          <img src={museum} alt='auckland museum'/>
        </div>
        <div className='cardCopy'>
          <p>Auckland Museum</p>
        </div>
      </div>

      <div className='card'>
        <div className='cardImage'>
          <img src={skycity} alt='sky city vip black room'/>
        </div>
        <div className='cardCopy'>
          <p>Sky City VIP Black Room</p>
        </div>
      </div>

      <div className='card'>
        <div className='cardImage'>
          <img src={stadium} alt='forsyth bar stadium'/>
        </div>
        <div className='cardCopy'>
          <p>Forsyth Barr Stadium</p>
        </div>
      </div>
    </div>
  )
}

export default ExampleWork
