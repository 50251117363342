import Navbar from './components/nav/Navbar'
import Hero from './components/Hero'
import About from './components/About'
import Services from './components/Services'
import ExampleWork from './components/ExampleWork'
import Contact from './components/Contact'

const App = () => {

  return (
    <>
      <div className="App">
        <Navbar />
        <Hero />
        <About />
        <ExampleWork />
        <Services />
        <Contact />
      </div>
    </>
  )
}

export default App
