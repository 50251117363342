import { useContext } from 'react'
import { Link } from 'react-scroll'

import '../../styles/nav.css'

import { NavToggleContext } from './NavToggleContext'

const FlyOutNav = () => {
  const [navToggle, setNavToggle] = useContext(NavToggleContext)
  const style = navToggle ? 'flyoutMenu show' : 'flyoutMenu'

  return (
    <div className={style}>
      <div className='flyoutMenuLinks'>
        <Link
          onClick={() => setNavToggle(false)}
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        ><h1>About</h1></Link>

        <Link
          onClick={() => setNavToggle(false)}
          activeClass="active"
          to="services"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        ><h1>Services</h1></Link>

        <Link
          onClick={() => setNavToggle(false)}
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        ><h1>Contact</h1></Link>

      </div>
    </div>
  )
}

export default FlyOutNav