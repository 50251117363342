import { Link } from 'react-scroll'

import downChevron from '../assets/images/down-chevron.png'

const Hero = () => {
  return (
    <div className='hero'>
      <Link
        activeClass="active"
        to="about"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        <img src={downChevron} className='down-arrow' alt='down arrow'/>
      </Link>
    </div>
  )
}

export default Hero
